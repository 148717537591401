import React from 'react'
import "./Nav.css"
import cart from "../../assets/cart.png"

const Nav3 = ({total}) => {
  return (
    <div className='nav4'>
        <div className="nav4Container">
        <img src={cart} alt="" />
        <p>{total}</p>
        <p>$</p>
        </div>
    </div>
  )
}

export default Nav3