// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCP0Yg2XaEmmj8jW1nE0LjWrOqOupUMxxI",
  authDomain: "security-ezzocard.firebaseapp.com",
  projectId: "security-ezzocard",
  storageBucket: "security-ezzocard.appspot.com",
  messagingSenderId: "347569136353",
  appId: "1:347569136353:web:9fb342c0e227c0d397cda0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export default db;