import React, { useEffect } from 'react'
import "./Nav.css"
import logo from "../../assets/logo.png"
import { Link } from 'react-router-dom'
const Nav2 = () => {

  return (
    <div className='nav3'>
        <div className="nav3Container">
            <Link to="/">
            <img src={logo} alt="" /> 
            </Link>
            <div className="pages">
                <a href="https://ezzocard.com/how-to-pay/">How to pay</a>
                <a href="https://ezzocard.com/checker/check-card-balance/">Card Balance</a>
                <a href="https://ezzocard.com/all-about-virtual-credit-cards/">About VCC</a>
                <a style={{textDecoration:"none"}} href="https://ezzocard.com/discount-list/" target='_blank'>Discounts</a>
                <a href="https://ezzocard.com/faq/" target='_blank'>FAQs</a>
                <a href="https://ezzocard.com/contact-us/" target='_blank'>Contact Us</a>
            </div>
        </div>
    </div>
  )
} 

export default Nav2