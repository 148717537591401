import React from 'react'
import "./Nav.css"
import cart from "../../assets/cart.png"
import menu from "../../assets/menu.png"
import { Link } from 'react-router-dom'

const Nav = ({total,setShowM}) => {
  return (
    <div className='nav'>
      <div className="navContainer">
        <a style={{color:"gray"}} href="mailto:support@ezzocard.com"><p>support@ezzocard.com</p></a>
        <div className="rightNav">
          <img src={cart} alt="" />
          <p>{total}</p>
          <p>$</p>
          <Link to={"/"}>
          <button>Buy Card</button>
          </Link>
        </div>
        <div className="rightNavBurger">
          <img onClick={()=>setShowM(false)} src={menu} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Nav
