import React from 'react'
import err from "../assets/err.jpg"

const Maintenance = () => {
  return (
    <div className='maintenance'>
        <div className="mainContainer">
            <h1>STATUS: En Maintenance</h1>
            <p>This site is currently under maintenance. We thank you for your understanding</p>
            <p>Coming soon...</p>
            <center>
            <img src={err} alt="" />
            </center>
        </div>
    </div>
  ) 
}

export default Maintenance