import React, { useEffect, useState } from 'react'
import "./Home.css"
import Nav from '../navbar/Nav'
import img from "../../assets/videoPrev.png"
import logo from "../../assets/logo.png"
import btc from "../../assets/bitcoin.svg"
import btc2 from "../../assets/bitcoin2.svg"
import usdt from "../../assets/usdt.png"
import pm from "../../assets/pm.svg"
import dogecoin from "../../assets/dogecoin.svg"
import litecoin from "../../assets/litecoin.svg"
import dlt from "../../assets/delete.png"
import Select from 'react-select';
import Footer from '../footer/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Nav3 from '../navbar/Nav3'
import Nav5 from '../navbar/Nav5'
import axios from 'axios'
import ProxyCheck from 'proxycheck-ts'



const Cards =({item,index,setPr,setPr2,setSh,op})=>{
  const [bg,setBg] = useState("rgb(168, 48, 215)");
  const [bg2,setBg2] = useState("white");
  const [i,setI] = useState();
  const tag = item.tag;

  useEffect(()=>{
    if (tag === "Orange") {
      setBg("Orange")
    }else if(tag === "Amazon"){
      setBg("rgb(51, 62, 72)")
    }else if(tag === "Pink"){
      setBg("rgb(251, 77, 145)")
    }else if(tag === "Brown"){
      setBg("rgb(126, 90, 36)")
    }
    else if(tag === "Yellow"){
      setBg("rgb(252, 199, 27)")
    }
  },[tag]);

  const changeBg=()=>{
    if (bg2 !== "white") {
      setBg2("white");
    }else{
      setBg2(bg);
      setI(index);
      setSh(index + 1)
    }
  }
  return(
    <div onClick={()=> changeBg()} style={i === index ? {backgroundColor:bg2} : {backgroundColor:"white"}} className="virtual2">
      <p>{item.price} {item.currency}</p>
      <div style={{backgroundColor:bg}} className="tag">{item.tag}</div>
    </div>
  )
}


const Home = ({total,setTotal,array,setArray,op,showM,setShowM}) => {
  const [q,setQ] = useState(0);
  const [q2,setQ2] = useState(0);
  const [q3,setQ3] = useState(0);
  const [q4,setQ4] = useState(0);
  const [q5,setQ5] = useState(0);
  const [q6,setQ6] = useState(0);
  const [q7,setQ7] = useState(0);
  const [q8,setQ8] = useState(0);
  const [q9,setQ9] = useState(0);
  const [q10,setQ10] = useState(0);
  const [q11,setQ11] = useState(0);
  const [q12,setQ12] = useState(0);
  const [q13,setQ13] = useState(0);
  const [q14,setQ14] = useState(0);
  const [q15,setQ15] = useState(0);
  const [q16,setQ16] = useState(0);
  const [q17,setQ17] = useState(0);
  const [q18,setQ18] = useState(0);
  const [q19,setQ19] = useState(0);
  const [q20,setQ20] = useState(0);
  const [q21,setQ21] = useState(0);
  const [q22,setQ22] = useState(0);
  const [q23,setQ23] = useState(0);
  const [q24,setQ24] = useState(0);
  const [sh,setSh] = useState(0)

  const [prix,setPrix] = useState(0)
  const [prix2,setPrix2] = useState(0)
  const [prix3,setPrix3] = useState(0)
  const [prix4,setPrix4] = useState(0)
  const [prix5,setPrix5] = useState(0)
  const [prix6,setPrix6] = useState(0)
  const [prix7,setPrix7] = useState(0)
  const [prix8,setPrix8] = useState(0)
  const [prix9,setPrix9] = useState(0)
  const [prix10,setPrix10] = useState(0)
  const [prix11,setPrix11] = useState(0)
  const [prix12,setPrix12] = useState(0)
  const [prix13,setPrix13] = useState(0)
  const [prix14,setPrix14] = useState(0)
  const [prix15,setPrix15] = useState(0)
  const [prix16,setPrix16] = useState(0)
  const [prix17,setPrix17] = useState(0)
  const [prix18,setPrix18] = useState(0)
  const [prix19,setPrix19] = useState(0)
  const [prix20,setPrix20] = useState(0)
  const [prix21,setPrix21] = useState(0)
  const [prix22,setPrix22] = useState(0)
  const [prix23,setPrix23] = useState(0)
  const [prix24,setPrix24] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null);

  const [pr,setPr] = useState(0);
  const [pr2,setPr2] = useState(0);

  const [qt,setQt] = useState(0);

  const cards = [
    {tag:"Amazon",price:50,currency:"USD",trueP:55.00},
    {tag:"Amazon",price:100,currency:"USD",trueP:108.99},
    {tag:"Amazon",price:200,currency:"USD",trueP:212.99},
    {tag:"Amazon",price:500,currency:"USD",trueP:515.99},
    {tag:"Orange",price:20,currency:"USD",trueP:34.99},
    {tag:"Yellow",price:50,currency:"USD",trueP:70.99},
    {tag:"Brown",price:50,currency:"CAD",trueP:56.99},
    {tag:"Orange",price:50,currency:"USD",trueP:70.99},
    {tag:"Pink",price:100,currency:"USD",trueP:141.99},
    {tag:"Yellow",price:100,currency:"USD",trueP:136.99},
    {tag:"Violet",price:100,currency:"USD",trueP:136.99},
    {tag:"Orange",price:100,currency:"USD",trueP:145.99},
    {tag:"Magenta",price:200,currency:"USD",trueP:279.99},
    {tag:"Brown",price:200,currency:"CAD",trueP:209.99},
    {tag:"Pink",price:200,currency:"USD",trueP:272.99},
    {tag:"Orange",price:200,currency:"USD",trueP:245.99},
    {tag:"Yellow",price:500,currency:"USD",trueP:606.99},
    {tag:"Magenta",price:500,currency:"USD",trueP:649.99},
    {tag:"Violet",price:500,currency:"USD",trueP:619.99},
    {tag:"Pink",price:500,currency:"USD",trueP:608.99},
    {tag:"Brown",price:500,currency:"CAD",trueP:489.99},
    {tag:"Yellow",price:1000,currency:"USD",trueP:1150.99},
    {tag:"Violet",price:1000,currency:"USD",trueP:1169.99},
    {tag:"Yellow",price:2000,currency:"USD",trueP:2185.99},










]

const navigation = useNavigate();


const [userIP, setUserIP] = useState(null);


const isBot = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const botKeywords = ['bot', 'googlebot', 'crawler', 'spider', 'robot', 'crawling','vpn'];
  return botKeywords.some(keyword => userAgent.includes(keyword));
}



const fetchIP = async () => {
  try {
    const response = await axios.get('https://api64.ipify.org?format=json');
    setUserIP(response.data.ip);
  } catch (error) {
    console.error('Error fetching IP:', error);
  }
};
useEffect(() => {
  fetchIP();
}, []); 


const [vpn,setVpn] = useState(false)
const [proxy,setproxy] = useState(false)



const checkIPReputation = async () => {
  try {
    

    const response = await axios.get(`https://vpnapi.io/api/${userIP}?key=499ae545bf694319a38061adf736b20d`)
    console.log(response.data);
    setVpn(response.data.security.vpn)
    setproxy(response.data.security.proxy)
    return response.data;

  } catch (error) {
    console.error('Error checking IP reputation:', error);
    return null;
  } 
};


useEffect(()=>{ 
 
  checkIPReputation();
},[userIP]);

useEffect(()=>{
  const res = isBot();
  if (res || vpn || proxy) {
    window.location.href = 'https://blog.com';
  }
  console.log(vpn)
},[vpn,proxy]);
  const options = [
    { value: 'btc', label: 'Bitcoin(BTC)' },
    { value: 'eth', label: 'Ethereum(ETH)' },
    { value: 'ltc', label: 'LiteCoin' },
    { value: 'doge', label: 'DogeCoin' },
    { value: 'bch', label: 'Bitcoin cash(BCH)' }, 
    { value: 'usdt', label: 'Tether(USDT)' },
    { value: 'pm', label: 'Perfect money(USD)' },


  ];


  useEffect(()=>{
    setPrix(55 * q)

  },[q])

  useEffect(()=>{
    setPrix2(108.99 * q2)

  },[q2])
  useEffect(()=>{
    setPrix3(212.99 * q3)

  },[q3])
  useEffect(()=>{
    setPrix4(515.99 * q4)

  },[q4])
  useEffect(()=>{
    setPrix5(34.99 * q5)

  },[q5])
  useEffect(()=>{
    setPrix6(70.99 * q6)

  },[q6])
  useEffect(()=>{
    setPrix7(56.99 * q7)

  },[q7])
  useEffect(()=>{
    setPrix8(70.99 * q8)

  },[q8])
  useEffect(()=>{
    setPrix9(141.99 * q9)

  },[q9])
  useEffect(()=>{
    setPrix10(136.99 * q10)

  },[q10])
  useEffect(()=>{
    setPrix11(136.99 * q11)

  },[q11])
  useEffect(()=>{
    setPrix12(145.99 * q12)

  },[q12])
  useEffect(()=>{
    setPrix13(279.99 * q13)

  },[q13])
  useEffect(()=>{
    setPrix14(209.99 * q14)

  },[q14])
  useEffect(()=>{
    setPrix15(272.99 * q15)

  },[q15])
  useEffect(()=>{
    setPrix16(245.99 * q16)

  },[q16])
  useEffect(()=>{
    setPrix17(606.99 * q17)

  },[q17])
  useEffect(()=>{
    setPrix18(649.99 * q18)

  },[q18])
  useEffect(()=>{
    setPrix19(619.99 * q19)
  },[q19])
  useEffect(()=>{
    setPrix20(608.99 * q20)
  },[q20])
  useEffect(()=>{
    setPrix21(489.99 * q21)
  },[q21])
  useEffect(()=>{
    setPrix22(1150.99 * q22)
  },[q22])
  useEffect(()=>{
    setPrix23(1169.99 * q23)
  },[q23])

  useEffect(()=>{
    setPrix24(2185.99 * q24)
    if (q24 === 0) {
    }
  },[q24])

  const [show,setShow] = useState(true);

  useEffect(()=>{
    const time = setTimeout(()=>{
      setShow(false);
    },1500);

    return ()=> clearTimeout(time);
  },[]);

  useEffect(()=>{
    setTotal(2185.99 * q24 + 1169.99 * q23 + 21150.99 * q22 +489.99 * q21 + 608.99 * q20 + 619.99 * q19 + 649.99 * q18 + 606.99 * q17 +
      245.99 * q16 + 272.99 * q15 + 209.99 * q14 + 279.99 * q13 + 145.99 * q12 + 136.99 * q11 + 136.99 * q10 + 141.99 * q9 +70.99 * q8 +
      56.99 * q7 + 70.99 * q6 + 34.99 * q5 + 515.99 * q4 + 212.99 * q3 + 108.99 * q2 + 55 * q);
      setArray({q:q,q2:q2,q3:q3,q4:q4,q5:q5,q6:q6,q7:q7,q8:q8,q9:q9,q10:q10,q11:q11,q12:q12,q13:q13,q14:q14,q15:q15,q16:q16,q17:q17,q18:q18,q19:q19,q20:q20,q21:q21,q22:q22,q23:q23,q24:q24});
  },[q,q2,q3,q4,q5,q6,q7,q8,q9,q10,q11,q12,q13,q14,q15,q16,q17,q18,q19,q20,q21,q22,q23,q24])

  return (
    <div style={{width:"100%"}}>
      {show && (
        <div className="layer">
        
        </div>
      )}
      <Nav total={total} setShowM={setShowM}/>

      <div className="banner">
        <div className="bannerComponent">
        <div className="nav2">
          <img src={logo} alt="" />
          <div className="pages2">
          <a style={{textDecoration:"none"}} href="https://ezzocard.com/how-to-pay/"><p>How to play</p></a>
            <a style={{textDecoration:"none"}} href="https://ezzocard.com/checker/check-card-balance/"><p>Card balance</p></a>
            <a style={{textDecoration:"none"}} href="https://ezzocard.com/all-about-virtual-credit-cards/"><p>About VCC</p></a>
            <a style={{textDecoration:"none"}} href="https://ezzocard.com/discount-list/"><p>Discounts</p></a>
            <a style={{textDecoration:"none"}} href="https://ezzocard.com/faq/"><p>FAQs</p></a>
            <a style={{textDecoration:"none"}} href="https://ezzocard.com/contact-us/"><p>Contact Us</p></a>
            
          </div> 
        </div>
        <div className="bannerContainer">
          <div className="leftBanner"> 
            <h1>Visa and Mastercard Prepaid Card Online Shop</h1>
            <p>Premium fully automated service with immediate delivery.</p>
            <button>Buy Card Now</button>
            <button className='watch'>Watch Video</button>
          </div>
          <div className="rightBanner">
            <img src={img} alt="" />
          </div>
        </div>
        </div>
      </div>

      <div className="content">
        <div className="contentContainer">
          <div className="annonce">
            <center>
            <h1>What We Offer</h1>
            <p>We offer virtual <strong>prepaid Visa and Mastercard</strong> bank cards that allow you to pay for goods and services <strong>anonymously</strong> online. We work worldwide so you can purchase virtual prepaid cards from anywhere in the world and use them to pay online on any websites that accept Mastercard or Visa prepaid cards.</p>
            <div className="warnning">
              <p>
              <strong>WARNING!</strong> Please note that the unique genuine Ezzocard service is only available at <strong>EZZOCARD</strong>. All other websites using the similar name but with different domain names are to be considered as fake and totally unsafe.
              </p>
            </div>
            </center>
          </div>

          <div className="types">
            <center>
            <h3>WE PROVIDE DIFFERENT TYPES OF CARDS, DEPENDING ON THEIR FEATURES:</h3>
            </center>
            <center>
            <div className="typesContainer">
              <div className="type">
                <div className="typeContainer">
                  <h4>Violet Card – virtual prepaid Visa, USD:</h4>
                  <div className="tag"><p>Violet</p></div>
                  <ul>
                    <li>Brand - Visa</li>
                    <li>USD,</li>
                    <li>No registration required,</li>
                    <li>Pay worldwide in any,</li>
                    <li>Term of validity – 6 months from the date of issue,</li>
                    <li>Period of use – 4-6 months *,</li>
                    <li>No monthly fee,</li>
                    <li>Non-USD transaction fee - 3%,</li>
                    <li>Denominations from 5 to 2000 USD are available.</li>
                  </ul>
                </div>
              </div>
              <div className="type">
                <div className="typeContainer">
                  <h4>Yellow Card – virtual prepaid Mastercard, USD:</h4>
                  <div style={{backgroundColor:"#fcc71b"}} className="tag"><p>Yellow</p></div>
                  <ul>
                    <li>Brand – Mastercard,</li>
                    <li>USD,</li>
                    <li>No registration required,</li>
                    <li>Pay worldwide in any,</li>
                    <li>Term of validity – 12 months from the date of issue,</li>
                    <li>Period of use – 10-12 months *,</li>
                    <li>No monthly fee,</li>
                    <li>Denominations from 10 to 2000 USD are available.</li>
                  </ul>
                </div>
              </div>

              <div className="type">
                <div className="typeContainer">
                  <h4>Brown Card – virtual prepaid Mastercard or Visa, CAD:</h4>
                  <div style={{backgroundColor:"#7e5a24"}} className="tag"><p>Brown</p></div>
                  <ul>
                    <li>Brand – Mastercard or Visa,</li>
                    <li>CAD (Canadian Dollars),</li>
                    <li>Registration to any billing address in Canada,</li>
                    <li>Use it to pay in any,</li>
                    <li>Term of validity – 3 years from the date of issue,</li>
                    <li>Period of use – 32-36 months *,</li>
                    <li>Monthly fee – $3 per month (starts 12 month after the date of issue),</li>
                    <li>Single transaction limit of $70 CAD and card lifetime maximum spend of $225 CAD for purchases in EEA,</li>
                    <li>Denominations ​​from 25 to 250 CAD are available.</li>
                  </ul>
                </div>
              </div>

              <div className="type">
                <div className="typeContainer">
                  <h4>Orange Card – virtual prepaid Visa, USD:</h4>
                  <div style={{backgroundColor:"#f7931a"}} className="tag"><p>Orange</p></div>
                  <ul>
                    <li>Brand - Visa</li>
                    <li>USD,</li>
                    <li>Registration to any billing address in USA,</li>
                    <li>Use it to pay in USD only,</li>
                    <li>May not be used at any merchant outside the U.S.,</li>
                    <li>Term of validity – 5-8 years from the date of issue,</li>
                    <li>Period of use – 58-96 months *,</li>
                    <li>No monthly fee,</li>
                    <li>Denominations from 25 to 200 USD are available.</li>
                  </ul>
                </div>
              </div>

              <div className="type">
                <div className="typeContainer">
                  <h4>Pink Card – virtual prepaid Mastercard, USD:</h4>
                  <div style={{backgroundColor:"#fb4d91"}} className="tag"><p>Pink</p></div>
                  <ul>
                    <li>Brand – Mastercard,</li>
                    <li>Currency – USD,</li>
                    <li>Pre-registered at a U.S. billing address (if address verification is required, ask Support Team),</li>
                    <li>Pay worldwide in any currency,</li>
                    <li>Term of validity – 6 months from the date of issue,</li>
                    <li>Period of use – 4-6 months **,</li>
                    <li>No monthly fee,</li>
                    <li>Non-USD transaction fee - 3%,</li>
                    <li>Denominations from 100 to 500 USD are available.</li>
                  </ul>
                </div>
              </div>

              <div className="type">
                <div className="typeContainer">
                  <h4>Magenta Card – virtual prepaid Visa, USD:</h4>
                  <div className="tag"><p>Magenta</p></div>
                  <ul>
                    <li>Brand - Visa</li>
                    <li>Currency – USD,</li>
                    <li>Pre-registered at a U.S. billing address (if address verification is required, ask Support Team),</li>
                    <li>Pay worldwide in any currency,</li>
                    <li>Term of validity – 6 months from the date of issue,</li>
                    <li>Period of use – 4-6 months **,</li>
                    <li>No monthly fee,</li>
                    <li>Non-USD transaction fee - 3%,</li>
                    <li>Denominations from 100 to 500 USD are available.</li>
                  </ul>
                </div>
              </div>

              <div className="type">
                <div className="typeContainer">
                  <h4>Amazon card – Amazon.com gift cards, USD:</h4>
                  <div style={{backgroundColor:"#333e48"}} className="tag"><p>Amazon</p></div>
                  <ul>
                    <li>Brand - Amazon</li>
                    <li>Currency - USD,</li>
                    <li>Can only be used with a verified account on Amazon U.S. (Amazon.com),</li>
                    <li>Can only be used to purchase eligible goods and services on Amazon.com and certain related sites as provided in the Amazon.com Gift Card Terms and Conditions,</li>
                    <li>Cannot be redeemed on Amazon sites other than Amazon.com,</li>
                    <li>Non-refundable,</li>
                    <li>Denominations from 50 to 500 USD are available.</li>
                  </ul>
                </div>
              </div>

            </div>
            </center>
          </div>

          <div className="methodes">
            <div className="methodesContainer">
              <p>* The variability of the card period of use is due to the time lapse between the validity period beginning (date of issue) and the moment of the card's actual sale to the buyer (beginning of use).</p>
              <p className='desc'>All virtual prepaid cards support AVS (Address Verification Service) and can be registered under any name and address in USA or Canada. No verification is needed during Ezzocard card registration, neither ID nor any other documents are required. Card registration is made on our website. You can always check your current card balance or get your card statement anytime. All purchased cards can be used to shop online, pay for services all over the world as well as to verify personal PayPal or other online accounts: iOffer, MoneyBookers US, Google Adwords, AlertPay, etc.</p>
            <div className="methodesElement">
              <h4>Payment Methods:</h4>
              <div className='allMethodes'>
              <img src={btc} alt="" />
              <p>BTC</p>
              <img src={litecoin} alt="" />
              <p>LTC</p>
              <img src={dogecoin} alt="" />
              <p>DOGE</p>
              <img src={btc2} alt="" />
              <p>BCH</p>
              <img src={usdt} alt="" />
              <p>USDT</p>
              <img src={pm} alt="" />
              <p>Perfect Money</p>
              </div>
            </div>
            </div>
          </div>


          <div className="virtuals">
            <div className="virtualsContainer">
              <center>
                <h1>Virtual Prepaid Cards</h1>
                <p style={{textAlign:"center",marginTop:"-8px",marginBottom:"20px"}}>Your cards will be delivered automatically after a successful payment.</p>
              </center>
              <center className='vir'>
              <div className="virtualsElements2">
                {cards.map((item,index)=>{ return(
                  <Cards setSh={setSh} setPr={setPr} setPr2={setPr2} item={item} index={index}/>
                )})}
              </div>




              {sh === 1 && (
                <div className="virtual">
                <div className="heading">
                  <div className='element'> 
                  <p>50 USD</p>
                  <div style={{backgroundColor:"#333e48"}} className="tag">
                    <p>Amazon</p>
                  </div>
                  </div>
                </div>
                <div className="bd">
                  <p className='priceTitle'>Price</p>
                  <div style={{marginLeft:"85px"}} className="price">
                  <p>$55.00</p>
                  <p className="noPrice">$58</p>
                  </div>
                </div>
                <div className="bd">
                  <p className='priceTitle'>Quantity</p>
                  <div className="price">
                  <button onClick={()=> setQ(prev => prev - 1)} className="plus">
                    -
                  </button>
                  <div className="q">
                    <p>{q}</p>
                  </div> 
                  <button onClick={()=> setQ(prev => prev + 1)} className='moin'>
                    +
                  </button>
                  </div>
                </div>
                <div style={{borderBottom:"none"}} className="bd">
                  <p className='priceTitle'>Subtotal</p>
                  <div className="price">
                  <p>${prix}</p>
                  </div>
                </div>
              </div>
              )}

                {sh === 2 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#333e48"}} className="tag">
                      <p>Amazon</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$108.99</p>
                    <p className="noPrice">$114.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ2(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q2}</p>
                    </div> 
                    <button onClick={()=> setQ2(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix2}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 3 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>200 USD</p>
                    <div style={{backgroundColor:"#333e48"}} className="tag">
                      <p>Amazon</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$212.99</p>
                    <p className="noPrice">$222.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ3(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q3}</p>
                    </div> 
                    <button onClick={()=> setQ3(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix3}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 4 && (
                  <div className="virtual">
                  <div className="heading"> 
                    <div className='element'> 
                    <p>500 USD</p>
                    <div style={{backgroundColor:"#333e48"}} className="tag">
                      <p>Amazon</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$515.99</p>
                    <p className="noPrice">$525.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ4(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q4}</p>
                    </div> 
                    <button onClick={()=> setQ4(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix4}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 5 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>20 USD</p>
                    <div style={{backgroundColor:"#f7931a"}} className="tag">
                      <p>Orange</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$34.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ5(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q5}</p>
                    </div> 
                    <button onClick={()=> setQ5(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix5}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 6 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>50 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Yellow</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$70.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ6(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q6}</p>
                    </div> 
                    <button onClick={()=> setQ6(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix6}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 7 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>50 CAD</p>
                    <div style={{backgroundColor:"#7e5a24"}} className="tag">
                      <p>Brown</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$56.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ7(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q7}</p>
                    </div> 
                    <button onClick={()=> setQ7(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix7}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 8 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>50 USD</p>
                    <div style={{backgroundColor:"#f7931a"}} className="tag">
                      <p>Orange</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$70.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ8(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q8}</p>
                    </div> 
                    <button onClick={()=> setQ8(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix8}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 9 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#fb4d91"}} className="tag">
                      <p>Pink</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$141.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ9(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q9}</p>
                    </div> 
                    <button onClick={()=> setQ9(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix9}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 10 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Yellow</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$136.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ10(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q10}</p>
                    </div> 
                    <button onClick={()=> setQ10(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix10}</p>
                    </div>
                  </div>
                </div>
                )}

               {sh === 11 &&(
                 <div className="virtual">
                 <div className="heading">
                   <div className='element'> 
                   <p>100 USD</p>
                   <div className="tag">
                     <p>Violet</p>
                   </div>
                   </div>
                 </div>
                 <div className="bd">
                   <p className='priceTitle'>Price</p>
                   <div style={{marginLeft:"85px"}} className="price">
                   <p>$136.99</p>
                   </div>
                 </div>
                 <div className="bd">
                   <p className='priceTitle'>Quantity</p>
                   <div className="price">
                   <button onClick={()=> setQ11(prev => prev - 1)} className="plus">
                     -
                   </button>
                   <div className="q">
                     <p>{q11}</p>
                   </div> 
                   <button onClick={()=> setQ11(prev => prev + 1)} className='moin'>
                     +
                   </button>
                   </div>
                 </div>
                 <div style={{borderBottom:"none"}} className="bd">
                   <p className='priceTitle'>Subtotal</p>
                   <div className="price">
                   <p>${prix11}</p>
                   </div>
                 </div>
               </div>
               )}

                {sh === 12 &&(
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#f7931a"}} className="tag">
                      <p>Orange</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$145.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ12(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q12}</p>
                    </div> 
                    <button onClick={()=> setQ12(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix12}</p>
                    </div>
                  </div>
                </div>
                )}

               {sh === 13 && (
                 <div className="virtual">
                 <div className="heading">
                   <div className='element'> 
                   <p>200 USD</p>
                   <div className="tag">
                     <p>Magenta</p>
                   </div>
                   </div>
                 </div>
                 <div className="bd">
                   <p className='priceTitle'>Price</p>
                   <div style={{marginLeft:"85px"}} className="price">
                   <p>$279.99</p>
                   </div>
                 </div>
                 <div className="bd">
                   <p className='priceTitle'>Quantity</p>
                   <div className="price">
                   <button onClick={()=> setQ13(prev => prev - 1)} className="plus">
                     -
                   </button>
                   <div className="q">
                     <p>{q13}</p>
                   </div> 
                   <button onClick={()=> setQ13(prev => prev + 1)} className='moin'>
                     +
                   </button>
                   </div>
                 </div>
                 <div style={{borderBottom:"none"}} className="bd">
                   <p className='priceTitle'>Subtotal</p>
                   <div className="price">
                   <p>${prix13}</p>
                   </div>
                 </div>
               </div>
               )}

                {sh === 14 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>200 USD</p>
                    <div style={{backgroundColor:"#7e5a24"}} className="tag">
                      <p>Brown</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$209.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ14(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q14}</p>
                    </div> 
                    <button onClick={()=> setQ14(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix14}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 15 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>200 USD</p>
                    <div style={{backgroundColor:"#fb4d91"}} className="tag">
                      <p>Pink</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$272.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ15(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q15}</p>
                    </div> 
                    <button onClick={()=> setQ15(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix15}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 16 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>200 USD</p>
                    <div style={{backgroundColor:"#f7931a"}} className="tag">
                      <p>Orange</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$245.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ16(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q16}</p>
                    </div> 
                    <button onClick={()=> setQ16(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix16}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 17 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>500 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Yellow</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$606.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ17(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q17}</p>
                    </div> 
                    <button onClick={()=> setQ17(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix17}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 18 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>500 USD</p>
                    <div className="tag">
                      <p>Magenta</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$649.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ18(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q18}</p>
                    </div> 
                    <button onClick={()=> setQ18(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix18}</p>
                    </div>
                  </div>
                </div>
                )}

               {sh === 19 && (
                 <div className="virtual">
                 <div className="heading">
                   <div className='element'> 
                   <p>500 USD</p>
                   <div className="tag">
                     <p>Violet</p>
                   </div>
                   </div>
                 </div>
                 <div className="bd">
                   <p className='priceTitle'>Price</p>
                   <div style={{marginLeft:"85px"}} className="price">
                   <p>$619.99</p>
                   </div>
                 </div>
                 <div className="bd">
                   <p className='priceTitle'>Quantity</p>
                   <div className="price">
                   <button onClick={()=> setQ19(prev => prev - 1)} className="plus">
                     -
                   </button>
                   <div className="q">
                     <p>{q19}</p>
                   </div> 
                   <button onClick={()=> setQ19(prev => prev + 1)} className='moin'>
                     +
                   </button>
                   </div>
                 </div>
                 <div style={{borderBottom:"none"}} className="bd">
                   <p className='priceTitle'>Subtotal</p>
                   <div className="price">
                   <p>${prix19}</p>
                   </div>
                 </div>
               </div>
               )}

               {sh === 20 && (
                 <div className="virtual">
                 <div className="heading">
                   <div className='element'> 
                   <p>500 USD</p>
                   <div style={{backgroundColor:"#fb4d91"}} className="tag">
                     <p>Pink</p>
                   </div>
                   </div>
                 </div>
                 <div className="bd">
                   <p className='priceTitle'>Price</p>
                   <div style={{marginLeft:"85px"}} className="price">
                   <p>$608.99</p>
                   </div>
                 </div>
                 <div className="bd">
                   <p className='priceTitle'>Quantity</p>
                   <div className="price">
                   <button onClick={()=> setQ20(prev => prev - 1)} className="plus">
                     -
                   </button>
                   <div className="q">
                     <p>{q20}</p>
                   </div> 
                   <button onClick={()=> setQ20(prev => prev + 1)} className='moin'>
                     +
                   </button>
                   </div>
                 </div>
                 <div style={{borderBottom:"none"}} className="bd">
                   <p className='priceTitle'>Subtotal</p>
                   <div className="price">
                   <p>${prix20}</p>
                   </div>
                 </div>
               </div>
               )}

                {sh === 21 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#7e5a24"}} className="tag">
                      <p>Brown</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$489.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ21(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q21}</p>
                    </div> 
                    <button onClick={()=> setQ21(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix21}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 22 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Amazon</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$1150.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ22(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q22}</p>
                    </div> 
                    <button onClick={()=> setQ22(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix22}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 23 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div className="tag">
                      <p>Violet</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$1169.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ23(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q23}</p>
                    </div> 
                    <button onClick={()=> setQ23(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix23}</p>
                    </div>
                  </div>
                </div>
                )}

                {sh === 24 && (
                  <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Yellow</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$2185.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price">
                    <button onClick={()=> setQ24(prev => prev - 1)} className="plus">
                      -
                    </button>
                    <div className="q">
                      <p>{q24}</p>
                    </div> 
                    <button onClick={()=> setQ24(prev => prev + 1)} className='moin'>
                      +
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix24}</p>
                    </div>
                  </div>
                </div>
                )}




              </center>
              <div className="virtualsElements">

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>50 USD</p>
                    <div style={{backgroundColor:"#333e48"}} className="tag">
                      <p>Amazon</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$55.00</p>
                    <p className="noPrice">$58</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q}</p>
                    </div> 
                    <button onClick={()=> setQ(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#333e48"}} className="tag">
                      <p>Amazon</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$108.99</p>
                    <p className="noPrice">$114.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ2(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q2}</p>
                    </div> 
                    <button onClick={()=> setQ2(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix2}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>200 USD</p>
                    <div style={{backgroundColor:"#333e48"}} className="tag">
                      <p>Amazon</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$212.99</p>
                    <p className="noPrice">$222.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ3(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q3}</p>
                    </div> 
                    <button onClick={()=> setQ3(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix3}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading"> 
                    <div className='element'> 
                    <p>500 USD</p>
                    <div style={{backgroundColor:"#333e48"}} className="tag">
                      <p>Amazon</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$515.99</p>
                    <p className="noPrice">$525.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ4(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q4}</p>
                    </div> 
                    <button onClick={()=> setQ4(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix4}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>20 USD</p>
                    <div style={{backgroundColor:"#f7931a"}} className="tag">
                      <p>Orange</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$34.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ5(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q5}</p>
                    </div> 
                    <button onClick={()=> setQ5(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix5}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>50 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Yellow</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$70.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ6(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q6}</p>
                    </div> 
                    <button onClick={()=> setQ6(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix6}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>50 CAD</p>
                    <div style={{backgroundColor:"#7e5a24"}} className="tag">
                      <p>Brown</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$56.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ7(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q7}</p>
                    </div> 
                    <button onClick={()=> setQ7(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix7}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>50 USD</p>
                    <div style={{backgroundColor:"#f7931a"}} className="tag">
                      <p>Orange</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$70.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ8(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q8}</p>
                    </div> 
                    <button onClick={()=> setQ8(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix8}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#fb4d91"}} className="tag">
                      <p>Pink</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$141.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ9(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q9}</p>
                    </div> 
                    <button onClick={()=> setQ9(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix9}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Yellow</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$136.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ10(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q10}</p>
                    </div> 
                    <button onClick={()=> setQ10(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix10}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div className="tag">
                      <p>Violet</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$136.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ11(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q11}</p>
                    </div> 
                    <button onClick={()=> setQ11(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix11}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#f7931a"}} className="tag">
                      <p>Orange</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$145.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ12(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q12}</p>
                    </div> 
                    <button onClick={()=> setQ12(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix12}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>200 USD</p>
                    <div className="tag">
                      <p>Magenta</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$279.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ13(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q13}</p>
                    </div> 
                    <button onClick={()=> setQ13(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix13}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>200 USD</p>
                    <div style={{backgroundColor:"#7e5a24"}} className="tag">
                      <p>Brown</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$209.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ14(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q14}</p>
                    </div> 
                    <button onClick={()=> setQ14(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix14}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>200 USD</p>
                    <div style={{backgroundColor:"#fb4d91"}} className="tag">
                      <p>Pink</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$272.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ15(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q15}</p>
                    </div> 
                    <button onClick={()=> setQ15(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix15}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>200 USD</p>
                    <div style={{backgroundColor:"#f7931a"}} className="tag">
                      <p>Orange</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$245.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ16(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q16}</p>
                    </div> 
                    <button onClick={()=> setQ16(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix16}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>500 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Yellow</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$606.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ17(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q17}</p>
                    </div> 
                    <button onClick={()=> setQ17(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix17}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>500 USD</p>
                    <div className="tag">
                      <p>Magenta</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$649.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ18(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q18}</p>
                    </div> 
                    <button onClick={()=> setQ18(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix18}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>500 USD</p>
                    <div className="tag">
                      <p>Violet</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$619.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ19(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q19}</p>
                    </div> 
                    <button onClick={()=> setQ19(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix19}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>500 USD</p>
                    <div style={{backgroundColor:"#fb4d91"}} className="tag">
                      <p>Pink</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$608.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ20(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q20}</p>
                    </div> 
                    <button onClick={()=> setQ20(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix20}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#7e5a24"}} className="tag">
                      <p>Brown</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$489.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ21(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q21}</p>
                    </div> 
                    <button onClick={()=> setQ21(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix21}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Amazon</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$1150.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ22(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q22}</p>
                    </div> 
                    <button onClick={()=> setQ22(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix22}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div className="tag">
                      <p>Violet</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$1169.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ23(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q23}</p>
                    </div> 
                    <button onClick={()=> setQ23(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix23}</p>
                    </div>
                  </div>
                </div>

                <div className="virtual">
                  <div className="heading">
                    <div className='element'> 
                    <p>100 USD</p>
                    <div style={{backgroundColor:"#fcc71b"}} className="tag">
                      <p>Yellow</p>
                    </div>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Price</p>
                    <div style={{marginLeft:"85px"}} className="price">
                    <p>$2185.99</p>
                    </div>
                  </div>
                  <div className="bd">
                    <p className='priceTitle'>Quantity</p>
                    <div className="price2">
                    <button onClick={()=> setQ24(prev => prev + 1)} className="plus">
                      +
                    </button>
                    <div className="q">
                      <p>{q24}</p>
                    </div> 
                    <button onClick={()=> setQ24(prev => prev - 1)} className='moin'>
                      -
                    </button>
                    </div>
                  </div>
                  <div style={{borderBottom:"none"}} className="bd">
                    <p className='priceTitle'>Subtotal</p>
                    <div className="price">
                    <p>${prix24}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="discount">
                <h2>ENTER A DISCOUNT CODE</h2>
                <input type="text" placeholder='xxx-xxx-xxx'/>
                <button>Apply</button>
              </div>
              <div className="discount2">
                <div className="discountContainer">
                <h2>ENTER A DISCOUNT CODE</h2>
                <input type="text" placeholder='xxx-xxx-xxx'/>
                <button>Apply</button>
                </div>
              </div>

              <center>
                <h1>Check your cart</h1>
                
                <div className="cart">

                  {q > 0 && <div className="cartItem">
                    <h5>50 USD Brown Mastercard</h5>                  
                    <div className="sectiondel">
                    <h5>$55.00 x {q}</h5>
                    <h5>{q * 55.00}</h5>
                      <img onClick={()=> setQ(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q2 > 0 && <div className="cartItem">
                    <h5>1000 USD Violet Visa</h5>
                    <div className="sectiondel">
                    <h5>$108.99 x {q2}</h5>
                    <h5>{q2 * 108.99}</h5>
                      <img onClick={()=> setQ2(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q3 > 0 && <div className="cartItem">
                    <h5>50 USD Amazon Amazon</h5>
                    <div className="sectiondel">
                    <h5>$212.99 x {q3}</h5>
                    <h5>{q3 * 212.99}</h5>
                      <img onClick={()=> setQ3(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q4 > 0 && <div className="cartItem">
                    <h5>100 USD Amazon Amazon</h5>
                    <div className="sectiondel">
                    <h5>$515.99 x {q4}</h5>
                    <h5>{q4 * 515.99}</h5>
                      <img onClick={()=> setQ4(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q5 > 0 && <div className="cartItem">
                    <h5>20 USD Orange Visa</h5>
                    <div className="sectiondel">
                    <h5>$34.99 x {q5}</h5>
                    <h5>{q5 * 34.99}</h5>
                      <img onClick={()=> setQ5(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q6 > 0 && <div className="cartItem">
                    <h5>50 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$70.99 x {q6}</h5>
                    <h5>{q6 * 70.99}</h5>
                      <img onClick={()=> setQ6(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q7 > 0 && <div className="cartItem">
                    <h5>50 USD Brown Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$56.99 x {q7}</h5>
                    <h5>{q7 * 56.99}</h5>
                      <img onClick={()=> setQ7(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q8 > 0 && <div className="cartItem">
                    <h5>50 USD Orange Visa</h5>
                    <div className="sectiondel">
                    <h5>$70.99 x {q8}</h5>
                    <h5>{q8 * 70.99}</h5>
                      <img onClick={()=> setQ8(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q9 > 0 && <div className="cartItem">
                    <h5>100 USD Pink Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$141.99 x {q9}</h5>
                    <h5>{q9 * 141.99}</h5>
                      <img onClick={()=> setQ9(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q10 > 0 && <div className="cartItem">
                    <h5>100 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$136.99 x {q10}</h5>
                    <h5>{q10 * 136.99}</h5>
                      <img onClick={()=> setQ10(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q11 > 0 && <div className="cartItem">
                    <h5>100 USD Violet Visa</h5>
                    <div className="sectiondel">
                    <h5>$136.99 x {q11}</h5>
                    <h5>{q11 * 136.99}</h5>
                      <img onClick={()=> setQ11(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q12 > 0 && <div className="cartItem">
                    <h5>100 USD Orange Visa</h5>
                    <div className="sectiondel">
                    <h5>$145.99 x {q12}</h5>
                    <h5>{q12 * 145.99}</h5>
                      <img onClick={()=> setQ12(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q13 > 0 && <div className="cartItem">
                    <h5>200 USD Magenta Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$279.99 x {q13}</h5>
                    <h5>{q13 * 279.99}</h5>
                      <img onClick={()=> setQ13(0)} src={dlt} alt="" />
                    </div>
                  </div>}

                  {q14 > 0 && <div className="cartItem">
                    <h5>200 USD Brown Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$209.99 x {q14}</h5>
                    <h5>{q14 * 209.99}</h5>
                      <img onClick={()=> setQ14(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q15 > 0 && <div className="cartItem">
                    <h5>{q15 * 272.99}</h5>
                    <div className="sectiondel">
                    <h5>200 USD Pink Visa</h5>
                    <h5>$272.99 x {q15}</h5>
                      <img onClick={()=> setQ15(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q16 > 0 && <div className="cartItem">
                    <h5>200 USD Orange Visa</h5>
                    <div className="sectiondel">
                    <h5>$245.99 x {q16}</h5>
                    <h5>{q16 * 245.99}</h5>
                      <img onClick={()=> setQ16(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q17 > 0 && <div className="cartItem">
                    <h5>500 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$606.99 x {q17}</h5>
                    <h5>{q17 * 606.99}</h5>
                      <img onClick={()=> setQ17(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q18 > 0 && <div className="cartItem">
                    <h5>500 USD Magenta Visa</h5>
                    <div className="sectiondel">
                    <h5>$649.99 x {q18}</h5>
                    <h5>{q18 * 649.99}</h5>
                      <img onClick={()=> setQ18(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q19 > 0 && <div className="cartItem">
                    <h5>500 USD Violet Visa</h5>
                    <div className="sectiondel">
                    <h5>$619.99 x {q19}</h5>
                    <h5>{q19 * 619.99}</h5>
                      <img onClick={()=> setQ19(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q20 > 0 && <div className="cartItem">
                    <h5>500 USD Pink Visa</h5>
                    <div className="sectiondel">
                    <h5>$608.99 x {q20}</h5>
                    <h5>{q20 * 608.99}</h5>
                      <img onClick={()=> setQ20(0)} src={dlt} alt="" />
                    </div>
                  </div>}

                  {q21 > 0 && <div className="cartItem">
                    <h5>500 USD Brown Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$489.99 x {q21}</h5>
                    <h5>{q21 * 489.99}</h5>
                      <img onClick={()=> setQ21(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q22 > 0 && <div className="cartItem">
                    <h5>1000 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$1150.99x {q22}</h5>
                    <h5>{q22 * 1150.99}</h5>
                      <img onClick={()=> setQ22(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q23 > 0 && <div className="cartItem">
                    <h5>1000 USD Violet Visa</h5>
                    <div className="sectiondel">
                    <h5>$1169.99 x {q23}</h5>
                    <h5>{q23 * 1169.99}</h5>
                      <img onClick={()=> setQ23(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q24 > 0 && <div className="cartItem">
                    <h5>2000 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5 className='h'>$2185.99 x {q24}</h5>
                    <h5>{q24 * 2185.99}</h5>
                      <img onClick={()=> setQ24(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  <hr />

                </div>
                <div className="cartTotal">
                  <h3>TOTAL</h3>
                  <h3>{total}$</h3>
                </div>






                <Select className='selectCurrency'
                placeholder=""
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}/>

                <Link to={selectedOption? "/payment" : "/"} state={{currency:selectedOption}}>
                <button className='buyBut'>BUY NOW</button>
                </Link>
              </center> 
              <center>
              <div className="down">
                <p>
                1 The price is shown in real time. The exact exchange rates are set at the moment of the purchase.
                </p>
                <p>
                2 Please note that a mining fee is charged for processing USDT transactions. The amount of the fee to be paid depends on the workload of the blockchain.
                </p>
                <p>
                3 A 6.5% fee is added to the final amount when paying with Perfect Money.
                </p>
                <h6>
                What will you get after the payment?
                </h6>
                <p className='pp'>
                Once you have paid and your payment has been confirmed, your card number, its expiration date and CVV2 will be provided to you within a few minutes. Feel free to visit our FAQs page if you have any questions. In the event a problem with receiving your card occurs, we will make every effort to solve it as soon as possible.
                </p>
                <p className="pp">
                We strive to satisfy your needs and provide the best service to our clients.
                </p>
                </div>
              </center>
              <center style={{marginTop:"20px"}}>
              <div className="discount">
                <h2>SUBSCRIBE AND GET SPECIAL OFFERS AND NEWS</h2>
                <input type="text" placeholder='Enter your email'/>
                <button>Subscribe</button>
              </div>
              <div className="discount2">
                <div className="discountContainer">
                <h2>SUBSCRIBE AND GET SPECIAL OFFERS AND NEWS</h2>
                <input type="text" placeholder='Enter your email'/>
                <button>Subscribe</button>
                </div>
              </div>
              <h2 style={{fontFamily:"sans-serif",fontSize:"20px",marginTop:"30px"}}>Key Benefits of Our Financial Products</h2>
              </center>
              <div className="down2">
                <div className="down2Left">
                  <ul>
                    <li>
                    Card Anonymity - virtual bank cards can be registered in any name and to any real address, which allows you to make purchases without revealing your personal information.
                    </li>
                    <li>
                    Purchasing Process Secrecy – when making a purchase, you are not required to provide your personal data (ID, address, etc.) and you can use anonymous payment methods such as .
                    </li>
                    <li>
                    Instant 24/7 Delivery – an automated issuing service is fully available 24/7 to provide your cards right after payment.
                    </li>
                    <li>
                    Worldwide Access – our clients can buy a virtual card globally, in every country, and may use it in almost any place where prepaid Visa and Mastercard are accepted.
                    </li>
                  </ul>
                </div>
                <div className="down2Right">
                  <ul>
                    <li>Online Support – we ensure comprehensive online support service to our customers.</li>
                    <li>Possibility of Refund – there is a possibility of 95% refund of a card face value within 1 month if you have not used your card and decided that you don't need it.</li>
                  <li>Great Selection – we offer a wide range of card face value: from 5 to 1000 USD</li>
                  <li>Discounts for Regular Customers.</li>
                  </ul>
                </div> 
              </div> 
              <center>
              <h2 style={{fontFamily:"sans-serif",fontSize:"20px",marginTop:"30px"}}>How to Buy</h2>
              </center>
              <div className="down3">
                <p className='pp2'>
                Purchase our cards easily and quickly following the instructions in the How to Pay section on our website. We accept USD (Perfect Money) and (see above).
                </p>
                <p className='pp2'>
                For further information please visit our FAQ section. If you need to learn more about the use of virtual Ezzocard cards and payment options, we invite you to read articles in the About VCC section.
                </p>
                <p className='pp2'>
                If you have any questions or need help, feel free to contact our Support Team. Please, do not forget to report your e-mail address so we could respond if we are offline
                </p>
                <p className='pp2'>
                Our virtual prepaid cards are your real advantage in worldwide shopping!
                </p>
                </div>

            </div>
          </div>
 
        </div>
      </div>
      <Nav5 showM={showM} setShowM={setShowM}/>
      <Nav3 total={total}/>
      <Footer/>

    </div>
  )
}

export default Home
