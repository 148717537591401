import React from 'react'
import "./Footer.css"
import logo from "../../assets/logo.png"

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footerContainer">
        <div className="footerBox">
            <div className="f1">
                <img src={logo} alt="" />
                <p className='f1p'>Ezzocard offers you Visa and Mastercard prepaid virtual cards, which will make your online payment process easy, safe and anonymous around the world.</p>
                <div className="f1c">
                    <a href="https://www.facebook.com/Ezzocard/">Follow us on Facebook</a>
                </div>
            </div>
            <div className="f2">
                <h5>Menu</h5>
                <a href="https://ezzocard.com/how-to-pay/">How to pay</a>
                <a style={{textDecoration:"none"}} href="https://ezzocard.com/checker/check-card-balance/">Card and balance</a>
                <a href="https://ezzocard.com/faq/">FAQs</a>
                <a href="https://ezzocard.com/contact-us/">Contact us</a>
            </div>
            <div className="f2">
                <h5>About VCC</h5>
                <a href="https://ezzocard.com/all-about-virtual-credit-cards/">About virtual cards</a>
                <a href="https://ezzocard.com/paypal-verification/">How to use virtual cards</a>
                <a href="https://ezzocard.com/blog/">Blog</a>
            </div>
        </div>
      </div>
      <div className="bottomFooterContainer">
      <div className="bottomFooter">
        <p>Copyright © 2022 | ezzocard | All Rights Reserved</p>
        <div className='bf2'>
            <a href="">Terms and conditions</a>
            <p>|</p>
            <a href="">Privacy Policy</a>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Footer
