import React, { useEffect, useState } from 'react'
import "./Payment.css"
import { Link, useLocation } from 'react-router-dom'
import Nav from '../navbar/Nav';
import Nav2 from '../navbar/Nav2';
import { ethers } from 'ethers';
import check from "../../assets/check.png"
import dlt from "../../assets/delete.png"
import qr from "../../assets/qr.png"
import { Audio,Circles,ColorRing } from 'react-loader-spinner'
import Footer from '../footer/Footer';


const Payment3 = ({total,array,total2}) => {

    const location = useLocation();
    const currency = location.state.currency.label;
    const currency2 = location.state.currency.value;

    const [accountName,setAccountName] = useState(null);
    const [balance,setBalance] = useState(null);

    useEffect(()=>{
        console.log(currency)
    },currency)

    const [q,setQ] = useState(array.q);
  const [q2,setQ2] = useState(array.q2);
  const [q3,setQ3] = useState(array.q3);
  const [q4,setQ4] = useState(array.q4);
  const [q5,setQ5] = useState(array.q5);
  const [q6,setQ6] = useState(array.q6);
  const [q7,setQ7] = useState(array.q7);
  const [q8,setQ8] = useState(array.q8);
  const [q9,setQ9] = useState(array.q9);
  const [q10,setQ10] = useState(array.q10);
  const [q11,setQ11] = useState(array.q11);
  const [q12,setQ12] = useState(array.q12);
  const [q13,setQ13] = useState(array.q13);
  const [q14,setQ14] = useState(array.q14);
  const [q15,setQ15] = useState(array.q15);
  const [q16,setQ16] = useState(array.q16);
  const [q17,setQ17] = useState(array.q17);
  const [q18,setQ18] = useState(array.q18);
  const [q19,setQ19] = useState(array.q19);
  const [q20,setQ20] = useState(array.q20);
  const [q21,setQ21] = useState(array.q21);
  const [q22,setQ22] = useState(array.q22);
  const [q23,setQ23] = useState(array.q23);
  const [q24,setQ24] = useState(array.q24);


    const accountChanged=(account)=>{
      setAccountName(account);
      accountBalance(account);
    }

    const accountBalance=(account)=>{
      window.ethereum.request({
        method: "eth_getBalance",
        params: [String(account), "latest"],
      }).then((balance)=>{
        setBalance(ethers.formatEther(balance));
      });
    }

 
    const checkAccount=()=>{ 
      if(window.ethereum ){
        console.log("detected");
        window.ethereum.request({
          method: "eth_requestAccounts"
        }).then((result)=> {
          console.log(result[0])
          accountChanged([result[0]]);
        })
      }else{
        console.log("not detected");
      }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
  return (
    <div style={{width:"100%"}}>
    <Nav total={total}/>
    <Nav2/>

<div className='navigation'>
  <div className="navigationBox">
  <div className="navigationContainer">
    <div className="elements">
      <div className="round">
      <img src={check} alt="" />
      </div>
      <p>Select</p>
    </div>
    <div className="elements">
    <div className="round">
      <img src={check} alt="" />
      </div>
      <p>Confirm</p>
    </div>
    <div className="elements">
      <div className="round">
      <img src={check} alt="" />
      </div>
      <p>Pay</p>
    </div>
    <div className="elements">
    <div className="roundContainer">
    <div className="round2">
      <p>4</p>
    </div>
    </div>
    <p className='pGray'>Recieve</p>
    </div>
  </div>
  </div>
  <center>
  <div className="lineIndicator">
    <div style={{width:"80%"}} className="lineRealIndicator"></div>
  </div>
  </center>
</div>

<center>
  <div className="payementTitle">
    <h2>Step 4:</h2>
    <p>Receive your cards</p>
  </div>
  <div className="descPay">
  <p>Your payment will be accepted after receiving the required number of confirmations from the Bitcoin network. The confirmation time depends on the network's workload and does not usually exceed 60 minutes. The cards will be at your disposal a few minutes after we receive the full amount of your payment.</p>
  <strong><p style={{color:"black",marginTop:"10px"}}>Do not reload this page.</p></strong>
  <br />
  <ColorRing
  height="100"
  width="100"
  radius="9"
  color="green"
  ariaLabel="loading"
  colors={['#a0a0a0','#a0a0a0','#a0a0a0','#a0a0a0','#a0a0a0']}
  />

  <div className="yourId">
  <p className='idTitle'>Payment ID*:</p>
  {currency2 === "btc" && (<h5>bc1qd3h3jz2gec8mn2ujzpvpd7f7m0076fregcucl7</h5>)}
  {currency2 === "eth" && (<h5>0x24f21b8bC93eB9bD1e95b5076479E20c2b578F4B</h5>)}
  {currency2 === "ltc" && (<h5>ltc1qgzjjxt00qxf2k0g7kld0drjflt9dmnwqcct6gr</h5>)}
  {currency2 === "doge" && (<h5>D6FTJF9x8ePcH9aHCbNr3GuCdii2XLrqZe</h5>)}
  {currency2 === "bch" && (<h5>qr2qt2m7vp8r3thv0npgz8ygdx2fpughv58zcx08re</h5>)}
  {currency2 === "usdt" && (<h5>TFwia9QSBU6XPftjvwhN8bfug88yQsw75B</h5>)}

  
  <p style={{fontSize:'11px',marginTop:"-15px"}}>* Please save your Payment ID. It will help us to trace your payment in case of a problem.</p>
  </div>
  </div>
</center>
<center>                
                
                  
              </center> 
              

              <Footer/>
    </div>
  )
}

export default Payment3