import React, { useEffect, useState } from 'react'

const Timer2 = () => {


    const [min,setMin] = useState(0);
    const [min2,setMin2] = useState(3);
    const [sec,setSec] = useState(0);
    const [sec2,setSec2] = useState(0);


    var starting;

    useEffect(()=>{
       starting = setInterval(()=>{
        setSec(prev => prev - 1);

        if(sec === 0){
          setSec(9);
          setSec2(prev => prev - 1);
        }
        if (sec2 === 0 && sec === 0) {
          setSec2(5);
          setMin(prev => prev - 1);
        }
        if (min === 0 && sec === 0 && sec2 === 0) {
            setMin2(prev => prev - 1);
            setMin(9);
        }
        if (min2 === 0 && min === 0 && sec === 0 && sec2 === 0) {
          setSec(0);
          setSec2(0);
          setMin(0);
          setMin2(0);
        }
      },1000);
 
      return ()=> clearInterval(starting);
    });


    const stopC=()=>{
        clearInterval(starting)
      }
  
      useEffect(()=>{
        if(min2 === 0 && min === 0 && sec === 0 && sec2 === 0){
          stopC();
        }
      },[min2,min,sec,sec2])

  return (
    <div>
     {min2}{min}:{sec2}{sec}
    </div>
  )
}

export default Timer2
