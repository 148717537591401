import React, { useEffect, useState } from 'react'
import "./Payment.css"
import { Link, useLocation } from 'react-router-dom'
import Nav from '../navbar/Nav';
import Nav2 from '../navbar/Nav2';
import { ethers } from 'ethers';
import check from "../../assets/check.png"
import dlt from "../../assets/delete.png"
import Footer from '../footer/Footer';
import Timer from './Timer';

const Payment = ({total,array,total2,setTotal2}) => {

    const location = useLocation();
    const cur = location.state.currency
    const currency = location.state.currency.label;
    const currency2 = location.state.currency.value;

    const [accountName,setAccountName] = useState(null);
    const [balance,setBalance] = useState(null);

    useEffect(()=>{
      window.scrollTo(0,0);
    },[]);
    const convertUsd =async()=>{
      if (currency2 === "btc") {
        try {
          const response = await fetch(
            'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd'
          );
          const data = await response.json();
          const bitcoinPrice = data.bitcoin.usd;
          const btcAmount = (total / bitcoinPrice).toFixed(8); // Limiting to 8 decimal places
          setTotal2(btcAmount);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }else if(currency2 === "eth"){
        try {
          const response = await fetch(
            'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
          );
          const data = await response.json();
          const ethereumPrice = data.ethereum.usd;
          const ethAmount = (total / ethereumPrice).toFixed(8); // Limiting to 8 decimal places
          setTotal2(ethAmount);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }else if(currency2 === "ltc"){
        try {
          const response = await fetch(
            'https://api.coingecko.com/api/v3/simple/price?ids=litecoin&vs_currencies=usd'
          );
          const data = await response.json();
          const litecoinPrice = data.litecoin.usd;
          const ltcAmount = (total / litecoinPrice).toFixed(8); // Limiting to 8 decimal places
          setTotal2(ltcAmount);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }else if(currency2 === "doge"){
        try {
          const response = await fetch(
            'https://api.coingecko.com/api/v3/simple/price?ids=dogecoin&vs_currencies=usd'
          );
          const data = await response.json();
          const dogecoinPrice = data.dogecoin.usd;
          const dogeAmount = (total / dogecoinPrice).toFixed(8); // Limiting to 8 decimal places
          setTotal2(dogeAmount);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }else if(currency2 === "bch"){
        try {
          const response = await fetch(
            'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin-cash&vs_currencies=usd'
          );
          const data = await response.json();
          const bitcoinCashPrice = data['bitcoin-cash'].usd;
          const bchAmount = (total / bitcoinCashPrice).toFixed(8); // Limiting to 8 decimal places
          setTotal2(bchAmount);
        } catch (error) {
          console.error('Error fetching data:', error);
        }}else if(currency2 === "usdt"){
          try {
            const response = await fetch(
              'https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd'
            );
            const data = await response.json();
            const usdtPrice = data.tether.usd;
            const usdtAmount = (total / usdtPrice).toFixed(8); // Limiting to 8 decimal places
            setTotal2(usdtAmount);
          } catch (error) {
            console.error('Error fetching data:', error);
          }      }else if(currency2 === "pm"){
            try {
              const response = await fetch(
                'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin-cash&vs_currencies=usd'
              );
              const data = await response.json();
              const bitcoinCashPrice = data['bitcoin-cash'].usd;
              const bchAmount = (total / bitcoinCashPrice).toFixed(8); // Limiting to 8 decimal places
              setTotal2(bchAmount);
            } catch (error) {
              console.error('Error fetching data:', error);
            }      }
    }

    useEffect(()=>{
      convertUsd();
    },[currency])

    const [q,setQ] = useState(array.q);
  const [q2,setQ2] = useState(array.q2);
  const [q3,setQ3] = useState(array.q3);
  const [q4,setQ4] = useState(array.q4);
  const [q5,setQ5] = useState(array.q5);
  const [q6,setQ6] = useState(array.q6);
  const [q7,setQ7] = useState(array.q7);
  const [q8,setQ8] = useState(array.q8);
  const [q9,setQ9] = useState(array.q9);
  const [q10,setQ10] = useState(array.q10);
  const [q11,setQ11] = useState(array.q11);
  const [q12,setQ12] = useState(array.q12);
  const [q13,setQ13] = useState(array.q13);
  const [q14,setQ14] = useState(array.q14);
  const [q15,setQ15] = useState(array.q15);
  const [q16,setQ16] = useState(array.q16);
  const [q17,setQ17] = useState(array.q17);
  const [q18,setQ18] = useState(array.q18);
  const [q19,setQ19] = useState(array.q19);
  const [q20,setQ20] = useState(array.q20);
  const [q21,setQ21] = useState(array.q21);
  const [q22,setQ22] = useState(array.q22);
  const [q23,setQ23] = useState(array.q23);
  const [q24,setQ24] = useState(array.q24);


    const accountChanged=(account)=>{
      setAccountName(account);
      accountBalance(account);
    }

    const accountBalance=(account)=>{
      window.ethereum.request({
        method: "eth_getBalance",
        params: [String(account), "latest"],
      }).then((balance)=>{
        setBalance(ethers.formatEther(balance));
      });
    }

 
    const checkAccount=()=>{ 
      if(window.ethereum ){
        console.log("detected");
        window.ethereum.request({
          method: "eth_requestAccounts"
        }).then((result)=> {
          console.log(result[0])
          accountChanged([result[0]]);
        })
      }else{
        console.log("not detected");
      }
    }
  return (
    <div style={{width:"100%"}}>
    <Nav total={total}/>
    <Nav2/>

<div className='navigation'>
  <div className="navigationBox">
  <div className="navigationContainer">
    <div className="elements">
      <div className="round">
      <img src={check} alt="" />
      </div>
      <p>Select</p>
    </div>
    <div className="elements">
    <div className="roundContainer">
    <div className="round2">
      <p>2</p>
    </div>
    </div>
      <p>Confirm</p>
    </div>
    <div className="elements">
    <div className="round3">
      <p>2</p>
    </div>
    <p className='pGray'>Pay</p>
    </div>
    <div className="elements">
    <div className="round3">
      <p>2</p>
    </div>
    <p className='pGray'>Recieve</p>
    </div>
  </div>
  </div>
  <center>
  <div className="lineIndicator">
    <div className="lineRealIndicator"></div>
  </div>
  </center>
</div>

<center>
  <div className="payementTitle">
    <h2>Step 2:</h2>
    <p>Confirm Your Order</p>
  </div>
  <div className="descPay">
  <p>Your cards have been successfully booked</p>
  <p>You have 3 minutes to confirm your order and proceed to the payment process (Step 3)</p>
  <p>Do not reload this page.</p>
  </div>
</center>
<center>                
                <div className="cart2">
                  {q > 0 && <div className="cartItem">
                    <h5>50 USD Brown Mastercard</h5>                  
                    <div className="sectiondel">
                    <h5>$55.00 x {q}</h5>
                    <h5>{q * 55.00}</h5>
                      <img onClick={()=> setQ(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q2 > 0 && <div className="cartItem">
                    <h5>1000 USD Violet Visa</h5>
                    <div className="sectiondel">
                    <h5>$108.99 x {q2}</h5>
                    <h5>{q2 * 108.99}</h5>
                      <img onClick={()=> setQ2(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q3 > 0 && <div className="cartItem">
                    <h5>50 USD Amazon Amazon</h5>
                    <div className="sectiondel">
                    <h5>$212.99 x {q3}</h5>
                    <h5>{q3 * 212.99}</h5>
                      <img onClick={()=> setQ3(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q4 > 0 && <div className="cartItem">
                    <h5>100 USD Amazon Amazon</h5>
                    <div className="sectiondel">
                    <h5>$515.99 x {q4}</h5>
                    <h5>{q4 * 515.99}</h5>
                      <img onClick={()=> setQ4(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q5 > 0 && <div className="cartItem">
                    <h5>20 USD Orange Visa</h5>
                    <div className="sectiondel">
                    <h5>$34.99 x {q5}</h5>
                    <h5>{q5 * 34.99}</h5>
                      <img onClick={()=> setQ5(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q6 > 0 && <div className="cartItem">
                    <h5>50 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$70.99 x {q6}</h5>
                    <h5>{q6 * 70.99}</h5>
                      <img onClick={()=> setQ6(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q7 > 0 && <div className="cartItem">
                    <h5>50 USD Brown Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$56.99 x {q7}</h5>
                    <h5>{q7 * 56.99}</h5>
                      <img onClick={()=> setQ7(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q8 > 0 && <div className="cartItem">
                    <h5>50 USD Orange Visa</h5>
                    <div className="sectiondel">
                    <h5>$70.99 x {q8}</h5>
                    <h5>{q8 * 70.99}</h5>
                      <img onClick={()=> setQ8(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q9 > 0 && <div className="cartItem">
                    <h5>100 USD Pink Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$141.99 x {q9}</h5>
                    <h5>{q9 * 141.99}</h5>
                      <img onClick={()=> setQ9(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q10 > 0 && <div className="cartItem">
                    <h5>100 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$136.99 x {q10}</h5>
                    <h5>{q10 * 136.99}</h5>
                      <img onClick={()=> setQ10(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q11 > 0 && <div className="cartItem">
                    <h5>100 USD Violet Visa</h5>
                    <div className="sectiondel">
                    <h5>$136.99 x {q11}</h5>
                    <h5>{q11 * 136.99}</h5>
                      <img onClick={()=> setQ11(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q12 > 0 && <div className="cartItem">
                    <h5>100 USD Orange Visa</h5>
                    <div className="sectiondel">
                    <h5>$145.99 x {q12}</h5>
                    <h5>{q12 * 145.99}</h5>
                      <img onClick={()=> setQ12(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q13 > 0 && <div className="cartItem">
                    <h5>200 USD Magenta Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$279.99 x {q13}</h5>
                    <h5>{q13 * 279.99}</h5>
                      <img onClick={()=> setQ13(0)} src={dlt} alt="" />
                    </div>
                  </div>}

                  {q14 > 0 && <div className="cartItem">
                    <h5>200 USD Brown Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$209.99 x {q14}</h5>
                    <h5>{q14 * 209.99}</h5>
                      <img onClick={()=> setQ14(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q15 > 0 && <div className="cartItem">
                    <h5>{q15 * 272.99}</h5>
                    <div className="sectiondel">
                    <h5>200 USD Pink Visa</h5>
                    <h5>$272.99 x {q15}</h5>
                      <img onClick={()=> setQ15(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q16 > 0 && <div className="cartItem">
                    <h5>200 USD Orange Visa</h5>
                    <div className="sectiondel">
                    <h5>$245.99 x {q16}</h5>
                    <h5>{q16 * 245.99}</h5>
                      <img onClick={()=> setQ16(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q17 > 0 && <div className="cartItem">
                    <h5>500 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$606.99 x {q17}</h5>
                    <h5>{q17 * 606.99}</h5>
                      <img onClick={()=> setQ17(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q18 > 0 && <div className="cartItem">
                    <h5>500 USD Magenta Visa</h5>
                    <div className="sectiondel">
                    <h5>$649.99 x {q18}</h5>
                    <h5>{q18 * 649.99}</h5>
                      <img onClick={()=> setQ18(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q19 > 0 && <div className="cartItem">
                    <h5>500 USD Violet Visa</h5>
                    <div className="sectiondel">
                    <h5>$619.99 x {q19}</h5>
                    <h5>{q19 * 619.99}</h5>
                      <img onClick={()=> setQ19(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q20 > 0 && <div className="cartItem">
                    <h5>500 USD Pink Visa</h5>
                    <div className="sectiondel">
                    <h5>$608.99 x {q20}</h5>
                    <h5>{q20 * 608.99}</h5>
                      <img onClick={()=> setQ20(0)} src={dlt} alt="" />
                    </div>
                  </div>}

                  {q21 > 0 && <div className="cartItem">
                    <h5>500 USD Brown Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$489.99 x {q21}</h5>
                    <h5>{q21 * 489.99}</h5>
                      <img onClick={()=> setQ21(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q22 > 0 && <div className="cartItem">
                    <h5>1000 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5>$1150.99x {q22}</h5>
                    <h5>{q22 * 1150.99}</h5>
                      <img onClick={()=> setQ22(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q23 > 0 && <div className="cartItem">
                    <h5>1000 USD Violet Visa</h5>
                    <div className="sectiondel">
                    <h5>$1169.99 x {q23}</h5>
                    <h5>{q23 * 1169.99}</h5>
                      <img onClick={()=> setQ23(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  {q24 > 0 && <div className="cartItem">
                    <h5>2000 USD Yellow Mastercard</h5>
                    <div className="sectiondel">
                    <h5 className='h'>$2185.99 x {q24}</h5>
                    <h5>{q24 * 2185.99}</h5>
                      <img onClick={()=> setQ24(0)} src={dlt} alt="" />
                    </div>
                  </div>}
                  <hr />

                </div>
                <div className="cartTotal2">
                  <p>Payement Method:</p>
                  <p>{currency}</p>
                </div>
                <div className="cartTotal2">
                  <p className='ptt'>Total USD:</p>
                  <p>{total}$</p>
                </div>
                <div className="cartTotal2">
                  <p className='ptt'>Total {currency2} :</p>
                  <p>{total2} {currency2}</p>
                </div>
                <div className="cartTotal22">
                </div>

                <div className="timing">
                  <h3><Timer/> minutes remaining</h3>
                </div>
                <p>We recommend you enter your email so that our Support Team could contact you if necessary</p>
                <input className='emailInp' type="text" placeholder='Enter your Email'/>
              </center> 

              <center>
              <div className="paymentButtons">
                <Link className='canCont' to="/">
                <button className='can'>Cancel</button>
                </Link>
                <Link state={{currency:cur}} className='payCont' to="/paymentProcess">
                <button className='pay'>Pay with {currency2}</button>
                </Link>
              </div>
              </center>

              <Footer/>
    </div>
  )
}

export default Payment