import { useEffect, useState } from 'react';
import './App.css';
import Home from './components/Home/Home';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import Payment from './components/Payment/Payment';
import Payment2 from './components/Payment/Payment2';
import Payment3 from './components/Payment/Payment3';
import { doc, onSnapshot } from "firebase/firestore";
import db from './FirebaseSdk';
import Maintenance from './components/Maintenance';



function App() {
  const [total,setTotal] = useState(0);
  const [total2,setTotal2] = useState(0);
  const [array,setArray] = useState({});
  const [open,setOpen] = useState(true);
  const [showM,setShowM] = useState(true)

  const unsub = onSnapshot(doc(db, "admin", "clock"), (doc) => {
    console.log("Current data: ", doc.data());
    setOpen(doc.data().value);
});

  return (
    <Router>
      <Routes>
        <>
          <Route exact path="/" element={open ? <Home showM={showM} setShowM={setShowM} op={open} total={total} setTotal={setTotal} array={array} setArray={setArray} /> : <Maintenance total={total} setTotal={setTotal} array={array} setArray={setArray} />} />
          {open && (
            <>
            <Route path="/payment" element={<Payment total={total} setTotal={setTotal} total2={total2} setTotal2={setTotal2} array={array} setArray={setArray}/>} />
          <Route path="/paymentProcess" element={<Payment2 total={total} setTotal={setTotal} total2={total2} setTotal2={setTotal2} array={array} setArray={setArray}/>} />
          <Route path="/paymentCheck" element={<Payment3 total={total} setTotal={setTotal} total2={total2} setTotal2={setTotal2} array={array} setArray={setArray}/>} />
            </>
          )}

        </>
      </Routes>
    </Router>
  );
}
 
export default App;
 