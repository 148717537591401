import React, { useState } from 'react'

const Nav5 = ({showM,setShowM}) => {
  return (
    <div className={showM ? "nav55" : "nav5"}>
        <div className="nav5Container">
        <div className="nav5Header">
        <p onClick={()=> setShowM(true)}>x</p>
        </div>
        <div className="nav5Buttons">
        <button>Buy Card</button>
        <a style={{textDecoration:"none"}} href="https://ezzocard.com/discount-list/" target='_blank'><button className='watch'>Discount</button></a>
        </div>
        <div className="secs">
            <p>How to Pay</p>
            <p>Card Balance</p>
            <p>About VCC</p>
            <a style={{color:"white",textDecoration:"none"}} href="https://ezzocard.com/faq/" target='_blank'><p>FAQs</p></a>
            <a style={{color:"white",textDecoration:"none"}} href="https://ezzocard.com/contact-us/" target='_blank'><p>Contact Us</p></a>
            <a href="https://ezzocard.com/how-to-pay/"><p className='p2'>support@ezzocard.online</p></a>
            <p className='p2'>Follow us on facebook</p>      
        </div>
        </div>
    </div> 
  )
}

export default Nav5
