import React, { useEffect, useState } from 'react'
import "./Payment.css"
import { Link, useLocation } from 'react-router-dom'
import Nav from '../navbar/Nav';
import Nav2 from '../navbar/Nav2';
import { ethers } from 'ethers';
import check from "../../assets/check.png"
import dlt from "../../assets/delete.png"
import qr from "../../assets/qr.png"

import Footer from '../footer/Footer';
import Timer from './Timer';
import Timer2 from './Timer2';
import copy from 'copy-to-clipboard';


const Payment2 = ({total,array,total2}) => {

    const location = useLocation();
    const cur = location.state.currency
    const currency = location.state.currency.label;
    const currency2 = location.state.currency.value;

    const [accountName,setAccountName] = useState(null);
    const [balance,setBalance] = useState(null);

    useEffect(()=>{
      window.scrollTo(0,0);
    },[]);

    const [q,setQ] = useState(array.q);
  const [q2,setQ2] = useState(array.q2);
  const [q3,setQ3] = useState(array.q3);
  const [q4,setQ4] = useState(array.q4);
  const [q5,setQ5] = useState(array.q5);
  const [q6,setQ6] = useState(array.q6);
  const [q7,setQ7] = useState(array.q7);
  const [q8,setQ8] = useState(array.q8);
  const [q9,setQ9] = useState(array.q9);
  const [q10,setQ10] = useState(array.q10);
  const [q11,setQ11] = useState(array.q11);
  const [q12,setQ12] = useState(array.q12);
  const [q13,setQ13] = useState(array.q13);
  const [q14,setQ14] = useState(array.q14);
  const [q15,setQ15] = useState(array.q15);
  const [q16,setQ16] = useState(array.q16);
  const [q17,setQ17] = useState(array.q17);
  const [q18,setQ18] = useState(array.q18);
  const [q19,setQ19] = useState(array.q19);
  const [q20,setQ20] = useState(array.q20);
  const [q21,setQ21] = useState(array.q21);
  const [q22,setQ22] = useState(array.q22);
  const [q23,setQ23] = useState(array.q23);
  const [q24,setQ24] = useState(array.q24);


    const accountChanged=(account)=>{
      setAccountName(account);
      accountBalance(account);
    }

    const accountBalance=(account)=>{
      window.ethereum.request({
        method: "eth_getBalance",
        params: [String(account), "latest"],
      }).then((balance)=>{
        setBalance(ethers.formatEther(balance));
      });
    }

 
    const checkAccount=()=>{ 
      if(window.ethereum ){
        console.log("detected");
        window.ethereum.request({
          method: "eth_requestAccounts"
        }).then((result)=> {
          console.log(result[0])
          accountChanged([result[0]]);
        })
      }else{
        console.log("not detected");
      }
    }
   
  return (
    <div style={{width:"100%"}}>
    <Nav total={total}/>
    <Nav2/>

<div className='navigation'>
  <div className="navigationBox">
  <div className="navigationContainer">
    <div className="elements">
      <div className="round">
      <img src={check} alt="" />
      </div>
      <p>Select</p>
    </div>
    <div className="elements">
    <div className="round">
      <img src={check} alt="" />
      </div>
      <p>Confirm</p>
    </div>
    <div className="elements">
    <div className="roundContainer">
    <div className="round2">
      <p>3</p>
    </div>
    </div>
    <p className='pGray'>Pay</p>
    </div>
    <div className="elements">
    <div className="round3">
      <p>4</p>
    </div>
    <p className='pGray'>Recieve</p>
    </div>
  </div>
  </div>
  <center>
  <div className="lineIndicator">
    <div style={{width:"65%"}} className="lineRealIndicator"></div>
  </div>
  </center>
</div>

<center>
  <div className="payementTitle">
    <h2>Step 3:</h2>
    <p>Please send your money</p>
  </div>
  <div className="descPay">
  <p>You have 30 minutes to start the payment process</p>
  <p>Do not reload this page.</p>
  </div>
  <img className='qr' src={qr} alt="" />
</center>
<center>                

                {currency2 === "btc" && (
                  <div className="champs">
                  <p className='champTitle'>BTC address:</p>
                  <input value="bc1qd3h3jz2gec8mn2ujzpvpd7f7m0076fregcucl7" className='Inp' type="text" placeholder='Enter your Email'/>
                  <button onClick={()=> copy('bc1qd3h3jz2gec8mn2ujzpvpd7f7m0076fregcucl7')} className='copy'>Copy</button>
                  <p className='champDesc'>This is a one-time address. You can use it to pay only this particular order</p>
                  </div>
                )}

                {currency2 === "eth" && (
                  <div className="champs">
                  <p className='champTitle'>ETH address:</p>
                  <input value="0x24f21b8bC93eB9bD1e95b5076479E20c2b578F4B" className='Inp' type="text" placeholder='Enter your Email'/>
                  <button onClick={()=> copy('0x24f21b8bC93eB9bD1e95b5076479E20c2b578F4B')} className='copy'>Copy</button>
                  <p className='champDesc'>This is a one-time address. You can use it to pay only this particular order</p>
                  </div>
                )}
                

                {currency2 === "ltc" && (
                  <div className="champs">
                  <p className='champTitle'>LTC address:</p>
                  <input value="ltc1qgzjjxt00qxf2k0g7kld0drjflt9dmnwqcct6gr" className='Inp' type="text" placeholder='Enter your Email'/>
                  <button onClick={()=> copy('ltc1qgzjjxt00qxf2k0g7kld0drjflt9dmnwqcct6gr')} className='copy'>Copy</button>
                  <p className='champDesc'>This is a one-time address. You can use it to pay only this particular order</p>
                  </div>
                )}


                {currency2 === "doge" && (
                  <div className="champs">
                  <p className='champTitle'>DOGE address:</p>
                  <input value="D6FTJF9x8ePcH9aHCbNr3GuCdii2XLrqZe" className='Inp' type="text" placeholder='Enter your Email'/>
                  <button onClick={()=> copy('D6FTJF9x8ePcH9aHCbNr3GuCdii2XLrqZe')} className='copy'>Copy</button>
                  <p className='champDesc'>This is a one-time address. You can use it to pay only this particular order</p>
                  </div>
                )}


                {currency2 === "bch" && (
                  <div className="champs">
                  <p className='champTitle'>BCH address:</p>
                  <input value="qr2qt2m7vp8r3thv0npgz8ygdx2fpughv58zcx08re" className='Inp' type="text" placeholder='Enter your Email'/>
                  <button onClick={()=> copy('qr2qt2m7vp8r3thv0npgz8ygdx2fpughv58zcx08re')} className='copy'>Copy</button>
                  <p className='champDesc'>This is a one-time address. You can use it to pay only this particular order</p>
                  </div>
                )}



                {currency2 === "USDT" && (
                  <div className="champs">
                  <p className='champTitle'>Tron USDT address:</p>
                  <input value="TFwia9QSBU6XPftjvwhN8bfug88yQsw75B" className='Inp' type="text" placeholder='Enter your Email'/>
                  <button onClick={()=> copy('TFwia9QSBU6XPftjvwhN8bfug88yQsw75B')} className='copy'>Copy</button>
                  <p className='champDesc'>This is a one-time address. You can use it to pay only this particular order</p>
                  </div>
                )}

                  <div className="champs">
                  <p className='champTitle'>{currency2} amount:</p>
                  <input value={total2} className='Inp' type="text"/>
                  <button onClick={()=> copy(total2)} className='copy'>Copy</button>
                  <p className='champDesc'>Transaction fee is not included in the amount. You may need to pay it additionally.</p>
                  </div>
                
                  <div style={{marginTop:"25px"}} className="timing">
                  <h3 style={{fontSize:"17px"}}><Timer2/> minutes remaining</h3>
                  <p>Please click "Paid" as soon as you send your {currency}</p>
                </div>
              </center> 
              <center>
              <div className="paymentButtons">
                <Link className='canCont' to="/">
                <button className='can'>Cancel</button>
                </Link>
                <Link state={{currency:cur}} className='payCont' to="/paymentCheck">
                <button className='pay'>Paid</button> 
                </Link>
              </div>
              </center>

              <Footer/>
    </div>
  )
}

export default Payment2